/* Build Time Env Vars on Docker Run from env files */
export const {
    NODE_ENV = 'development',
    SERVICE_NAME = 'zhl-shareable-lo-links-ui',
    PAGE_FRAME_SERVICE = 'https://pf-int.dev.kong-nonprod.zg-int.net/',
    S3S_SERVICE = 'http://s3s.qa.zillow.net',
    GDP_COMMON = 'https://www.qa.zillow.net/graphql',
    GDP_INTERNAL = 'http://qa-graphql-data-provider.hdp0i.ecstest.zillow.net',
    LOAN_OFFICERS_URL = 'https://settings.dev.zillowhomeloans.net/api/v1/public/values/loan_officers_object',
    // set assetPrefix to blank to resolve assets locally else injected when deployed to the pod
    STATICS_CDN = '',
    ZHL_AGENT_PFS_API_KEY = '',
    SKIP_BUILD_STATIC_GENERATION = false,
    NEXT_PUBLIC_HAS_PROFILE_VIDEO = true,
    ZILLOW_HOME_LOANS_URL = 'https://www.qa.zillow.net/homeloans',
    SHAREABLE_LO_API_KEY = '',
} = process.env;

/* Run Time Bootstrap String Replaced Env Vars
 * NEXT_PUBLIC Are Available Client Side
 */
export const PFS_KONG_API_KEY = process.env.PFS_KONG_API_KEY as string;
export const MIN_MORTGAGE_API =
    (process.env.MIN_MORTGAGE_API as string) ||
    'https://mortgage-api-internal.qa.kong-nonprod.zg-int.net';
export const MIN_MORTGAGE_API_KEY = process.env.MIN_MORTGAGE_API_KEY as string;
export const NEXT_PUBLIC_ENV = process.env.NEXT_PUBLIC_ENV ?? 'local';
export const IS_ISR_REQUEST = Boolean(process.env.IS_ISR_REQUEST) ?? false;
export const SPLIT_AUTHORIZATION_KEY =
    process.env.NEXT_PUBLIC_SPLIT_AUTHORIZATION_KEY ?? 'localhost';

export const SERVER_MORTGAGE_API =
    process.env.SERVER_MORTGAGE_API ?? ('https://mortgageapi.qa.zillow.net' as string);
export const SERVER_MORTGAGE_PARTNER_ID = process.env.SERVER_MORTGAGE_PARTNER_ID ?? 'RD-YKJGBVW';
export const SHOULD_REFETCH_LENDER_ID = Boolean(process.env.SHOULD_REFETCH_LENDER_ID);

export const SERVICE_PORT = parseInt(process.env.PORT ?? '8000', 10);

export const datadogConfig = {
    applicationId: process.env.NEXT_PUBLIC_DD_SHAREABLE_LO_APP_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DD_SHAREABLE_LO_RUM_CLIENT_TOKEN as string,
};

export const NEXT_PUBLIC_MORTGAGE_API = process.env.NEXT_PUBLIC_MORTGAGE_API as string;
