/* eslint-disable zillow/@typescript-eslint/no-unsafe-member-access */
/* eslint-disable zillow/@typescript-eslint/naming-convention */
/* eslint-disable zillow/@typescript-eslint/restrict-template-expressions */
import type {
    LoanOfficerData,
    LoanOfficerDetails,
    LoanOfficerLenderId,
    LoanOfficerResponse,
    LoanOfficerReview,
    LoanOfficersList,
} from '@/models/loanOfficer.model';
import logger from './logger';
import type { ReviewFormValues } from '@/components/EditLenderReviewForm';
import type { LoanOfficerReviewInput, LoanOfficerReviewInputById } from './interfaces';
import {
    LOAN_PROGRAM_FRIENDLY_NAMES,
    SATISFACTION_MAP,
    getCatchClauseErrorMessage,
} from './formatter';
import { MIN_MORTGAGE_API, SERVER_MORTGAGE_API, SERVER_MORTGAGE_PARTNER_ID } from '@/config';
import type { ErrorObject } from './types';

const zipFormValidator = (input: string) => input.replace(/[^\d-]/g, '');

export const hasValidReviews = (reviews?: LoanOfficerReview[] | ErrorObject): boolean =>
    reviews != null &&
    !('error' in reviews) &&
    typeof reviews !== 'undefined' &&
    reviews.length > 0;

export const validateZipCode = async (zipCode: string): Promise<boolean> => {
    if (!zipFormValidator(zipCode)) {
        return false;
    }

    const url = `${SERVER_MORTGAGE_API}/getZIPCodeLocation?partnerId=${SERVER_MORTGAGE_PARTNER_ID}&locationRef.zipCode=${zipCode}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });
        const data = await response.json();
        return !!data.supportsMortgage;
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
            },
        });
        return false;
    }
};

export const createLenderReview = async (input: {
    caller: string;
    lenderId: string;
    review: ReviewFormValues;
}): Promise<Response | ErrorObject> => {
    const url = `${SERVER_MORTGAGE_API}/createLenderReview`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        });
        return response;
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                input,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

export const getLoanOfficerList = async (): Promise<LoanOfficersList> => {
    try {
        const response = await fetch(process.env.LOAN_OFFICERS_URL as string);
        const responseAsJSObject = (await response.json()) as LoanOfficerResponse;
        if (response.ok) {
            return responseAsJSObject.value;
        }

        logger.info({
            msg: `Failed to process agent permissions: ${
                response.statusText
            }: ${response.text.toString()}`,
            response: responseAsJSObject,
        });

        return {
            status: response.status,
            error: `${response.statusText}: ${response.text.toString()}`,
        };
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url: process.env.LOAN_OFFICERS_URL,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

export const getLoanOfficerNameFromNmlsid = (
    loanOfficerObject: LoanOfficersList,
    loanOfficerNmlsid: number
): string | undefined => {
    const loanOfficerName = Object.keys(loanOfficerObject).find((key: string) => {
        const loanOfficer = loanOfficerObject[key as keyof LoanOfficersList] as LoanOfficerDetails;
        return loanOfficer.nmlsID === loanOfficerNmlsid;
    });
    return loanOfficerName;
};

export const getLenderInformation = async ({
    loanOfficerNMLSId,
    lenderId,
    schedulingLink,
    fields,
    partnerId,
    uri,
}: {
    loanOfficerNMLSId?: number;
    lenderId?: string;
    schedulingLink?: string;
    fields?: string[];
    partnerId?: string;
    uri?: string;
}): Promise<Partial<LoanOfficerData> | LoanOfficerLenderId | ErrorObject> => {
    const input = {
        lenderRef: {
            ...(loanOfficerNMLSId ? { nmlsId: loanOfficerNMLSId } : {}),
            lenderId,
        },
        partnerId: partnerId ?? process.env.NEXT_PUBLIC_MORTGAGE_BACKEND_PARTNER_ID ?? '',
        fields: fields ?? [
            'nationallyRegistered',
            'nmlsId',
            'nmlsType',
            'stateLicenses',
            'emailAddress',
            'employerLenderId',
            'employerMemberFDIC',
            'employerNMLSId',
            'employerScreenName',
            'imageId',
            'rating',
            'totalReviews',
            'externalApplicationURL',
            'aboutMe',
            'address',
            'companyName',
            'equalHousingLogo',
            'hideOfficePhone',
            'individualName',
            'languagesSpoken',
            'officePhone',
            'screenName',
            'title',
        ],
    };
    const url = `${uri ?? process.env.NEXT_PUBLIC_MORTGAGE_API}/getRegisteredLender`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
        });

        if (response.ok) {
            const data = await response.json();
            return {
                ...data.lender,
                ...(schedulingLink ? { schedulingLink } : {}),
            } as Partial<LoanOfficerData>;
        }
        logger.error({
            msg: 'response for failed lender information',
            error: response.statusText,
            context: { response, input },
        });

        logger.error({
            error: response.statusText,
            response,
            nmlsId: loanOfficerNMLSId,
            api: process.env.NEXT_PUBLIC_MORTGAGE_API,
        });

        return {
            status: response.status,
            error: `Failed to fetch lender information: ${response.statusText}`,
        };
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                // eslint-disable-next-line zillow/@typescript-eslint/naming-convention
                mortage_api: process.env.NEXT_PUBLIC_MORTGAGE_API,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

export const getLoanOfficerImage = async (
    lenderId: string,
    imageId: string,
    treatment = '200x200'
): Promise<string | ErrorObject> => {
    const url = `${
        process.env.NEXT_PUBLIC_MORTGAGE_API
    }/getLenderProfileImage?${new URLSearchParams({
        lenderId,
        imageId,
        treatment,
    })}`;

    return url;
};

export const getReviewResponsesById = async (
    loanOfficerReviewInputById: LoanOfficerReviewInputById
) => {
    const { fields, reviewIds, caller } = loanOfficerReviewInputById;
    const input = { fields, reviewIds, caller };
    try {
        const opts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        };
        return await fetch(`${SERVER_MORTGAGE_API}/getFullLenderReviewsById`, opts);
    } catch (error) {
        const stringifiedError = error instanceof Error ? error.message : (error as string);
        const errorMsg = {
            error: stringifiedError,
            context: loanOfficerReviewInputById,
        };

        logger.error(errorMsg);
        return errorMsg;
    }
};

export const getReviewResponses = async (loanOfficerReviewInput: LoanOfficerReviewInput) => {
    const { totalReviews, lenderId, caller, isAuthored, isRuntime } = loanOfficerReviewInput;
    const totalPages = Math.ceil(totalReviews / 100);
    const maxMortgageAPI = isRuntime ? SERVER_MORTGAGE_API : process.env.NEXT_PUBLIC_MORTGAGE_API;
    const getInputs = (
        page: number,
        pageSize: number
    ): Record<string, string | { lenderId: string } | string[] | boolean | number> => {
        const authoredReviewsInputs = {
            lenderId,
            fields: [
                'reviewId',
                'created',
                'individualReviewee',
                'companyReviewee',
                'reviewChanges',
            ],
            page,
            pageSize,
            caller: caller ?? '',
        };

        const publishedReviewsInputs = {
            lenderRef: {
                lenderId,
            },
            partnerId: process.env.NEXT_PUBLIC_MORTGAGE_BACKEND_PARTNER_ID ?? '',
            fields: [
                'closeDateSatisfaction',
                'closingCostsSatisfaction',
                'content',
                'dateOfService',
                'details',
                'interestRateSatisfaction',
                'loanPurpose',
                'loanProgram',
                'loanType',
                'rating',
                'serviceProvided',
                'title',
                'zipCode',
                'companyReviewee',
                'created',
                'individualReviewee',
                'response',
                'reviewerName',
                'verifiedReviewer',
                'reviewId',
                'updated',
                'location',
            ],
            page: 1,
            pageSize: 100,
        };

        return isAuthored ? authoredReviewsInputs : publishedReviewsInputs;
    };

    try {
        interface Options {
            method: string;
            headers: {
                'Content-Type': string;
                'mode': string;
                'apikey': string;
            };
            body: string;
        }

        const fetchedReviews: Options[] = [];
        const url = isAuthored
            ? `${maxMortgageAPI}/getFullLenderReviews`
            : `${process.env.MIN_MORTGAGE_API}/getPublishedLenderReviews`;

        for (let i = 1; i <= totalPages; i += 1) {
            const input = getInputs(i, 100);
            logger.info({
                message: 'Fetching reviews',
                url,
                input,
            });
            const opts: Options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'mode': 'no-cors',
                    'apikey': process.env.MIN_MORTGAGE_API_KEY as string,
                },
                body: JSON.stringify(input),
            };
            fetchedReviews.push(opts);
        }

        const responses = await Promise.all(
            fetchedReviews.map(async (opts: Options) => fetch(url, opts))
        );
        if (!('error' in responses) && responses.every((response) => response.ok)) {
            return responses;
        }
        return { error: 'Bad request' };
    } catch (error) {
        const stringifiedError = error instanceof Error ? error.message : (error as string);
        const errorMsg = {
            error: stringifiedError,
            context: loanOfficerReviewInput,
        };

        logger.error(errorMsg);
        return errorMsg;
    }
};

export const getLoanOfficerReviewInformation = async (
    loanOfficerReviewInput: LoanOfficerReviewInput
): Promise<LoanOfficerReview[] | { error: string }> => {
    try {
        const responses = await getReviewResponses(loanOfficerReviewInput);
        if (!('error' in responses) && responses.every((response) => response.ok)) {
            const data = await Promise.all(responses.map(async (response) => response.json()));
            return data.map((d: { reviews: LoanOfficerReview[] }) => d.reviews).flat();
        }

        logger.error(responses);
        return { error: 'Failed to fetch lender reviews' };
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url: `${MIN_MORTGAGE_API}/getPublishedLenderReviews`,
            },
        });
        return { error: errorMessage };
    }
};

export enum reviewState {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export const updateLenderReview = async (input: {
    caller: string;
    reviewId: string;
    review: ReviewFormValues;
}): Promise<
    | { response: Response; state: reviewState.SUCCESS }
    | { status: number; error: string; state: reviewState.ERROR }
> => {
    const url = `${SERVER_MORTGAGE_API}/updateLenderReview`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        });
        if (response.ok) {
            return { response, state: reviewState.SUCCESS };
        }
        return { status: response.status, error: response.statusText, state: reviewState.ERROR };
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                input,
            },
        });
        return { status: 500, error: errorMessage, state: reviewState.ERROR };
    }
};

export const deleteLenderReview = async (input: {
    caller: string;
    reviewId: string;
}): Promise<Response | ErrorObject> => {
    const url = `${SERVER_MORTGAGE_API}/deleteLenderReview`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
            body: JSON.stringify(input),
        });
        return response;
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            msg: errorMessage,
            context: {
                url,
                input,
            },
        });
        return { status: 500, error: errorMessage };
    }
};

const formatDate = (date: string) => {
    const dateObj = new Date(Date.parse(date));
    const fDate = dateObj.toISOString().split('T')[0];
    return fDate;
};

/**
 * This function is for converting our form values to the accepted mortgageapi
 * values & following existing functionality on omitted fields in a review
 * (see https://mortgageapi.qa.zillow.net/?name=createLenderReview)
 *
 * formatting rating & date has also been moved here
 *
 * CASE 1: User hasn't inputted anything => FIELD DOES NOT EXIST
 * CASE 2: User puts 'Other' or 'Not Sure' => null => Mortgage API deletes field
 * CASE 3: User inputs something => The value they inputted
 *
 * data.details returns false if it has not been selected
 *
 * We assume that when populating the form, any nulls from the database will be converted
 * to their respective "Other" or "Not Sure" equivalents
 */

export const formatFormValuesForMortgageAPI = (data: ReviewFormValues) => ({
    ...data,
    dateOfService: formatDate(data.dateOfService),
    details: data.details !== false ? data.details : [],
    interestRateSatisfaction:
        data.interestRateSatisfaction === SATISFACTION_MAP.NotSure
            ? null
            : data.interestRateSatisfaction,
    closingCostsSatisfaction:
        data.closingCostsSatisfaction === SATISFACTION_MAP.NotSure
            ? null
            : data.closingCostsSatisfaction,
    closeDateSatisfaction:
        data.closeDateSatisfaction === SATISFACTION_MAP.NotSure ? null : data.closeDateSatisfaction,
    rating: parseInt(data.rating, 10),
    loanProgram: data.loanProgram === LOAN_PROGRAM_FRIENDLY_NAMES.Other ? null : data.loanProgram,
});
