/* eslint-disable zillow/@typescript-eslint/restrict-template-expressions */
import type { FetchedUser } from './auth';
import { getCatchClauseErrorMessage } from './formatter';
import logger from './logger';

const inBrowserAndPages =
    typeof window !== 'undefined' &&
    window.location.pathname &&
    window.location.pathname.startsWith('/pages');

export const LOGIN_URL = inBrowserAndPages
    ? 'https://localhost:8443/user/account/services/Login.htm'
    : '/user/account/services/Login.htm';
export const LOGOUT_URL = inBrowserAndPages ? 'https://localhost:8443/Logout.htm' : '/Logout.htm';
export const WHOAMI_URL = inBrowserAndPages
    ? 'https://localhost:8443/user/account/services/WhoAmI.htm'
    : '/user/account/services/WhoAmI.htm';

export const INVALID_TOKEN_MESSAGE = 'Invalid token';
export const LOGIN_REQUIRED_MESSAGE = 'Login required';
export const INVALID_LOGIN_MESSAGE = 'Invalid Login';

export const whoAmIMAX = async ({
    authToken,
}: { authToken?: string } = {}): Promise<FetchedUser> => {
    if (!authToken) {
        return Promise.resolve(undefined);
    }

    try {
        const response = await fetch('/homeloans/loan-officer/api/whoAmI', {
            method: 'POST',
            body: JSON.stringify({ caller: authToken }),
        });
        if (response.status === 200) {
            const data = (await response.json()) as FetchedUser;
            return data;
        }
        return await Promise.reject(new Error(response.statusText));
    } catch (e) {
        const errorMessage = getCatchClauseErrorMessage(e);
        logger.error({
            error: errorMessage,
        });
        return Promise.reject(new Error(errorMessage));
    }
};

export const rawWhoAmi = async () => {
    const rawResponse = await fetch(WHOAMI_URL, { method: 'POST', credentials: 'same-origin' });
    let response;

    try {
        response = await rawResponse.json();
    } catch {
        response = {};
    }
    if (response.message === INVALID_TOKEN_MESSAGE) {
        throw new Error(INVALID_TOKEN_MESSAGE);
    }
    if (!response.authToken) {
        throw new Error(LOGIN_REQUIRED_MESSAGE);
    }
    return response;
};

export const netLogin = async (username: string, password: string) => {
    const formData = `email=${encodeURIComponent(username)}&password=${encodeURIComponent(
        password
    )}`;
    const response = await fetch(LOGIN_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData,
        credentials: 'same-origin',
    });

    if (response.status === 200) {
        return response.json();
    }
    if (response.status === 301 || response.status === 302) {
        return rawWhoAmi();
    }
    if (response.status === 400) {
        const data = await response.json();
        throw new Error(data);
    }
    throw new Error(INVALID_LOGIN_MESSAGE);
};

export const netLogout = async () =>
    fetch(LOGOUT_URL, { method: 'GET', credentials: 'same-origin' });

export const netWhoAmi = async () => {
    const authData = await rawWhoAmi();
    return whoAmIMAX(authData);
};
