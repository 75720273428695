import { netWhoAmi, netLogin, netLogout, whoAmIMAX } from './auth-api';
import { ZHL_SHAREABLE_LO_AUTH_INFO } from './constants';
import { getCatchClauseErrorMessage } from './formatter';
import type { ErrorObject } from './types';

export interface User {
    authenticated: boolean;
    displayName: string;
    id: string;
    logon: string;
    permissions: string[];
    profileTypes: string[];
    screenName: string;
}
export interface AuthenticatedUser {
    user?: User;
    authToken: string;
}
export interface AuthenticatedUserWithExpiration extends AuthenticatedUser {
    expiry: number;
}

export type AuthUser = AuthenticatedUser | AuthenticatedUserWithExpiration;

export type FetchedUser = AuthenticatedUser | undefined | ErrorObject;

export type FetchedUserWithExpiration = AuthenticatedUserWithExpiration | ErrorObject | undefined;

const TTL = 1000 * 60 * 30; // 30 minutes

const getCachedAuthInfo = (): FetchedUserWithExpiration => {
    if (typeof window !== 'undefined') {
        const cachedAuthInfo = JSON.parse(
            localStorage.getItem(ZHL_SHAREABLE_LO_AUTH_INFO) ?? '{}'
        ) as FetchedUserWithExpiration;
        if (cachedAuthInfo && 'error' in cachedAuthInfo) {
            return cachedAuthInfo;
        }
        if (cachedAuthInfo?.authToken) {
            if (new Date().getTime() > cachedAuthInfo.expiry) {
                localStorage.removeItem(ZHL_SHAREABLE_LO_AUTH_INFO);
                return cachedAuthInfo;
            }
        }
        return cachedAuthInfo;
    }
    return undefined;
};

export const clearAuthInfo = () => {
    localStorage.removeItem(ZHL_SHAREABLE_LO_AUTH_INFO);
};

function setWithExpiry(
    key: string,
    value: AuthenticatedUser | AuthenticatedUserWithExpiration,
    ttl = TTL
): void {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        ...value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export const saveAuthInfo = (info: FetchedUser | FetchedUserWithExpiration) => {
    const cachedAuthInfo = getCachedAuthInfo();
    if (!info || 'error' in info || !info.authToken) {
        if (
            !cachedAuthInfo ||
            'error' in cachedAuthInfo ||
            ('expiry' in cachedAuthInfo && new Date().getTime() > cachedAuthInfo.expiry)
        ) {
            clearAuthInfo();
        }
        return;
    }
    setWithExpiry(ZHL_SHAREABLE_LO_AUTH_INFO, info);
};

export const tokenLogin = async (authToken: string | undefined | null) => {
    if (!authToken) {
        return Promise.reject(new Error('No auth token provided'));
    }
    const info = await whoAmIMAX({ authToken });
    // If token returns save info if not clear info
    if (info !== undefined && 'authToken' in info) {
        saveAuthInfo(info);
    } else {
        clearAuthInfo();
    }
    return info;
};

export const getAuthInfo = async (forceNetworkFetch = false): Promise<FetchedUser> => {
    const cachedAuthInfo = getCachedAuthInfo();
    if (
        cachedAuthInfo &&
        !('error' in cachedAuthInfo) &&
        cachedAuthInfo.expiry >= new Date().getTime() &&
        !forceNetworkFetch
    ) {
        return cachedAuthInfo;
    }
    try {
        const data = await netWhoAmi();
        // If token returns save info if not clear info
        if (data !== undefined && 'authToken' in data) {
            saveAuthInfo(data);
        } else {
            clearAuthInfo();
        }
        return data;
    } catch (e) {
        // We should save the result of this even if it is an error so that
        // we can avoid duplicate calls to WhoAmI.
        saveAuthInfo({ error: getCatchClauseErrorMessage(e) });
    }
    return undefined;
};

export const login = async (username: string, password: string) => netLogin(username, password);

export const logout = async () => {
    clearAuthInfo();
    return netLogout();
};

export const getAuthToken = async (): Promise<string | null> => {
    const authInfo = await getAuthInfo();
    if (authInfo && !('error' in authInfo)) {
        const { authToken } = authInfo satisfies AuthenticatedUser;
        return authToken;
    }
    return null;
};
